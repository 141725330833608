export default defineI18nConfig(() => ({
    legacy: false,
    missingWarn: false,
    fallbackWarn: false,
    locale: 'en_INT',
    // messages,
    fallbackLocale: {
        fr_CH: ['en_INT'],
        en_GB: ['en_INT'],
        en_US: ['en_INT'],
        fr_FR: ['en_INT'],
        sv_SE: ['en_INT'],
        da_DK: ['en_INT'],
        nl_NL: ['en_INT'],
        default: ['en_INT'],
    },
}));
